import logo from 'images/communities/Miami/logo.svg'
import secondaryLogo from 'images/communities/Miami/secondary-logo.svg'
import dashboardHeaderImage from 'images/communities/Miami/banner.jpg'
import loginBackgroundImg from 'images/communities/Miami/background-image.jpg'
import favicon from 'images/communities/Miami/favicon.png'
import homeLogo from 'images/home/community-logos/miami.png'
import { SURVEY_QUESTIONS } from 'types'

const config = {
  subdomain: 'miami',
  name: 'Miami',
  position: 9,
  partnerSupportEmail: 'artlook@miamifoundation.org',
  schoolSupportEmail: 'artlook@miamifoundation.org',
  stylesheet: 'miami.scss',
  mapRedirectPath: '',
  organizationUrl: 'https://miamifoundation.org/',
  secondaryOrganizationUrl: 'https://www3.dadeschools.net/home',
  faqUrl:
    'https://drive.google.com/file/d/1oH2dEra1lbFwANDTzFocMO8tRqQQ2Fby/view?usp=sharing',
  mapEnabled: true,
  schoolPortalEnabled: true,
  partnerPortalEnabled: true,
  partnerProgramsEnabled: true,
  educatorEnabled: false,
  notificationsEnabled: true,
  notificationListSize: -1,
  dataDashboardEnabled: true,
  id: 45,
}

// ----- SEARCH -----
config.search = {
  options: {
    blockList: ['networks', 'creativeSchoolsCategory', 'instructorsTeaching'],
  },
  map: {
    center: { lat: 25.777679, lng: -80.192116 },
    radius: 40, //in miles
  },
  schoolSearchFilters: [
    'network',
    'creativeSchoolsCategory',
    'instructorsTeaching',
    'schoolType',
    'organizationPartnersCount',
    'programsOffered',
    'disciplineInterests',
    'resourceProgramInterests',
    'coursesOffered',
    'studentDemographics',
    'schoolDistrict',
    'openToPartnerships',
  ],
  directoryFilters: {
    blockList: [
      'coursesOffered',
      'creativeSchoolsCategory',
      'instructorsTeaching',
      'partnerProgramTypes',
      'partnersCount',
      'programTypes',
      'resourceProgramInterests',
      'resourceTypes',
      'schoolDistrict',
      'schoolTypes',
      'schoolYears',
      'studentDemographics',
      'subDisciplines',
      'schools',
      'topics',
      'users',
      'networks',
    ],
  },
  forumFilters: {
    blockList: [
      'coursesOffered',
      'creativeSchoolsCategory',
      'instructorsTeaching',
      'partnerProgramTypes',
      'partnersCount',
      'programTypes',
      'resourceProgramInterests',
      'resourceTypes',
      'schoolDistrict',
      'schoolYears',
      'studentDemographics',
      'subDisciplines',
      'gradesServed',
      'networks',
      'neighborhoods',
    ],
  },
}

// ----- BRANDING -----

config.defaultBranding = {
  className: 'mia-community',
  favicon,
  mapLogo: {
    src: logo,
    alt: "Miami's homepage",
    link: config.organizationUrl,
  },
  homeLogo: {
    src: homeLogo,
    alt: 'The Miami Foundation',
  },
  communityLogo: {
    src: logo,
    alt: 'Artlook Schools',
  },
  secondarySideBySideLogo: {
    src: secondaryLogo,
    alt: 'Miami Dade County Public Schools Seal',
    link: config.secondaryOrganizationUrl,
  },
  dashboardHeader: {
    src: dashboardHeaderImage,
    alt: '',
  },
  loginBackgroundImg: {
    src: loginBackgroundImg,
    alt: '',
  },
  mapHero: {
    src: dashboardHeaderImage,
    alt: '',
  },
}

// ----- DISPLAY TEXT -----

/**
 * This object overrides the display text that is defined globally with string values that are
 * specific to the organization. Mustache-esque templating is available for dynamic strings using
 * {{ insertFieldName }} for interpolation.
 */

config.displayText = {
  map: {
    title: 'artlook Map: Bringing the power of the arts to ALL youth in Miami',
    searchHeader: 'Find Schools & Partners in Miami',
    welcomeSearchHeader: 'Explore Arts Education in Miami',
    welcomeHeader: `artlook<sup>®</sup> Miami`,
    welcomeMessage: `
      <p>
        artlook<sup>®</sup> Miami is your one-stop resource for information on arts education in Miami-Dade county, with a special focus on music education. Our community has made powerful strides towards removing barriers to quality music education for all children. Transparent, reliable data, however, is critical to strengthening our efforts to ensure every child has access to the full benefits music unlocks.
      </p>
      <p>
        Developed in close coordination with arts educators, administrators, and cultural organizations locally and nationwide, the Miami artlook<sup>®</sup> map identifies arts programs, educators and partners located in schools and communities, explains where and how arts programming and partnerships are happening, and highlights existing needs for students and classrooms.
      </p>
      <p>
        Start exploring Miami artlook<sup>®</sup> map today. Together, we can shape a community in which all children thrive through music.
      </p>
    `,
  },
  tagline: 'artlook Map: Bringing the power of the arts to ALL youth in Miami',
  partnerPortal: {
    dashboard: {
      welcomeMessage: `
        <h3>Transformative music education begins with you</h3>
        <p>
          <em>artlook<sup>®</sup> Miami</em> combines data from Miami-Dade county public schools and music education program providers (like you!). The more we know what programming is available to students inside and outside of school, the stronger we are able to support existing programs and help fill in the gaps to ensure equitable access to quality music education programming for EVERY student in our community.
        </p>
        <p><strong>To get started, follow the links in the boxes below to share your organization's program and school partnership information.</strong></p>
        <p>If you have any questions or need assistance, please contact <a href="mailto:${config.partnerSupportEmail}" target="_blank" rel="noopener noreferrer">${config.partnerSupportEmail}</a>.</p>
      `,
    },
  },
  schoolPortal: {
    dashboard: {
      welcomeMessage: `
        <h3>Transformative music education begins with you</h3>
        <p>
          <em>artlook<sup>®</sup> Miami</em> combines data from arts education program providers and Miami-Dade County Public Schools (like yours!). The more we know about the types of programming available to students inside and outside of school, the more we can support existing programs and ensure access to quality arts education programming for EVERY student in our community.
        </p>
        <p>
          <strong>
            To get started, follow the links in the boxes below to share your school's program and partnership interests.
          </strong>
        </p>
        <p>If you have any questions or need assistance, please contact <a href="mailto:${config.schoolSupportEmail}" target="_blank" rel="noopener noreferrer">${config.schoolSupportEmail}</a>.</p>
      `,
    },
  },
  survey: {
    getStarted: `<h2>Welcome to the {{ surveySchoolYearNumber }} Creative Schools Survey!</h2>
    <p>Through this annual survey, you will be asked to share information about arts education instruction at your school and partnerships with arts partners (i.e. arts organizations).</p>
    <p>As you work on the {{ surveySchoolYearNumber }} survey, please be sure to frequently save your responses to each question to avoid losing information. You are not required to complete your survey in one session; saving your responses as you go will allow you to return at any time to complete or edit your survey before submitting by July 31st.</p>
    <p>Please feel free to reach out to <a href="mailto:${config.schoolSupportEmail}" target="_blank" rel="noopener noreferrer">${config.schoolSupportEmail}</a> with any questions!</p>`,
    staffingQuestion: {
      modalMessage: `
        <p>
        <strong>Note on instructors:</strong> Restrict the list of instructors in your school to those teaching an arts course during the school day. Note that district-run/district-managed schools require arts instructors to hold an ISBE certification or endorsement in one of the four state-recognized disciplines (visual arts, music, theater, and/or dance). Charter schools, contract schools, and Pre-K-only schools do not have this requirement. The media arts discipline does not have an ISBE certification/endorsement; these instructors should be listed as well.
        </p>
        <p>
        <strong>Note on part/full-time status:</strong> Please select Full Time if a teacher had full-time status for the entire school year. If a teacher was part-time for all or part of the year, or full-time for only part of the year, please select Part Time.
        </p>
        <p>
        <strong>Note on disciplines:</strong> The Primary Focus of each instructor is required. You may optionally list a Secondary Focus as well. Please also select the “Classroom/non-arts teacher?” button if the instructor is not primarily an arts instructor (e.g., non-arts, general education teacher teaching arts courses).
        </p>
        `,
      displayOptions: {
        showClassNumber: true,
      },
    },
    instructorQuestion: {
      displayOptions: {
        showInstructorEmail: true,
      },
    },
  },
}

// ----- SURVEY QUESTIONS CONFIG -----

const {
  APPROACHES,
  GOVERNANCE,
  OBSTACLES_SELECT,
  OBSTACLES_DESCRIBE,
  OBSTACLES_OVERCOME,
  COMMUNITY_ENGAGEMENTS,
} = SURVEY_QUESTIONS

config.surveyQuestions = {
  approaches: APPROACHES.OPTIONS.GENERAL,
  governance: GOVERNANCE.OPTIONS.GENERAL,
  obstaclesSelect: OBSTACLES_SELECT.OPTIONS.GENERAL,
  obstaclesDescribe: OBSTACLES_DESCRIBE.OPTIONS.GENERAL,
  obstaclesOvercome: OBSTACLES_OVERCOME.OPTIONS.GENERAL,
  communityEngagement: COMMUNITY_ENGAGEMENTS.OPTIONS.GENERAL,
}

// ----- EDUCATOR CONFIG -----

config.educator = {
  communityOfPracticeEnabled: false,
}

export default config
