import logo from 'images/communities/Sacramento/logo2.png'
import dashboardHeaderImage from 'images/communities/Sacramento/banner.jpg'
import loginBackgroundImg from 'images/communities/Sacramento/background-image.jpg'
import mapHeaderImage from 'images/communities/Sacramento/landing.jpg'
import favicon from 'images/communities/Sacramento/favicon.ico'
import homeLogo from 'images/home/community-logos/sacramento.png'
import { SURVEY_QUESTIONS } from 'types'

const config = {
  subdomain: 'sacramento',
  name: 'Sacramento',
  position: 6,
  partnerSupportEmail: 'droofner@cityofsacramento.org',
  schoolSupportEmail: 'droofner@cityofsacramento.org',
  stylesheet: 'sacramento.scss',
  mapRedirectPath: '',
  organizationUrl: 'https://www.cityofsacramento.gov/ccs/oac',
  faqUrl:
    'https://drive.google.com/file/d/1oHweE17iNSQ0-KSlDtvRGyvRuRT2pYCd/view?usp=sharing',
  mapEnabled: true,
  schoolPortalEnabled: true,
  partnerPortalEnabled: true,
  partnerProgramsEnabled: true,
  educatorEnabled: false,
  notificationsEnabled: true,
  notificationListSize: -1,
  dataDashboardEnabled: true,
  id: 8,
}

// ----- SEARCH -----
config.search = {
  options: {
    blockList: ['networks', 'creativeSchoolsCategory'],
  },
  schoolSearchFilters: [
    'network',
    'creativeSchoolsCategory',
    'instructorsTeaching',
    'schoolType',
    'organizationPartnersCount',
    'programsOffered',
    'disciplineInterests',
    'resourceProgramInterests',
    'coursesOffered',
    'studentDemographics',
    'schoolDistrict',
    'openToPartnerships',
  ],
  map: {
    center: { lat: 38.3937983, lng: -121.4421321 },
  },
}

// ----- BRANDING -----

config.defaultBranding = {
  className: 'sac-community',
  favicon,
  mapLogo: {
    src: logo,
    alt: "Sacramento's homepage",
    link: config.organizationUrl,
  },
  homeLogo: {
    src: homeLogo,
    alt: 'Sacramento Metropolitan Arts Commission',
  },
  communityLogo: {
    src: logo,
    alt: 'Artlook Schools',
  },
  dashboardHeader: {
    src: dashboardHeaderImage,
    alt: '',
  },
  loginBackgroundImg: {
    src: loginBackgroundImg,
    alt: '',
  },
  mapHero: {
    src: mapHeaderImage,
    alt: '',
  },
}

// ----- DISPLAY TEXT -----

/**
 * This object overrides the display text that is defined globally with string values that are
 * specific to the organization. Mustache-esque templating is available for dynamic strings using
 * {{ insertFieldName }} for interpolation.
 */

config.displayText = {
  tagline:
    "Let's increase access to arts education for all Sacramento County students.",
  map: {
    title: 'artlook Map: Increasing Arts Education in Sacramento',
    searchHeader: 'Find Schools & Partners in Sacramento',
    welcomeSearchHeader: 'Explore Arts Education in Sacramento',
    welcomeHeader: `artlook<sup>®</sup> Sacramento`,
    welcomeMessage: `
      <p>artlook<sup>®</sup> is your one-stop-shop for information on arts education in our city. With thousands of data points, artlook<sup>®</sup> provides the information you need to make strategic decisions about expanding the arts in Sacramento County Schools.</p>

      <p>Developed in close coordination with Sacramento's arts educators, this platform can show you everything from which art teachers and arts partners are in which schools to what arts programming, coursework, and minutes of instruction is happening where.</p>

      <p>Start exploring artlook<sup>®</sup> today, and help us expand arts education access, quality, and equity for every child, in every grade, in every school.</p>
    `,
  },
  partnerPortal: {
    dashboard: {
      welcomeMessage: `
        <p>
          <strong><em>artlook<sup>®</sup> Sacramento</em> is YOUR gallery for arts education data</strong>
        </p>
        <p>
          <em>artlook<sup>®</sup> Sacramento</em> is the only platform of its kind that combines data from Sacramento-area public schools and arts education program providers (like you!). The goal is to build direct connections between arts providers and schools to expand equitable access to quality arts education programming for EVERY student in our community.
        </p>
        <p>
          <strong>To get started, follow the links in the boxes below to share your organization's program and school partnership information.
        </p>
        <p>
        If you have any questions or need assistance, please contact Diana Roofner at <a href="mailto:${config.partnerSupportEmail}" target="_blank" rel="noopener noreferrer">${config.partnerSupportEmail}</a>.
        </p>
      `,
    },
  },
  schoolPortal: {
    dashboard: {
      welcomeMessage: `
        <h3><em>artlook<sup>®</sup> Sacramento</em> is YOUR gallery for arts education data</h3>
        <p>
          <em>artlook<sup>®</sup> Sacramento</em> is the only platform of its kind that combines data from Sacramento-area public schools (like you!) and arts education program providers. The goal is to build direct connections between arts providers and schools to expand equitable access to quality arts education programming for EVERY student in our community.
        </p>
        <p>
          <strong>To get started, follow the link below to complete a brief survey about your school's arts education offerings and partnerships.
        </p>
        <p>
        If you have any questions or need assistance, please contact Diana Roofner, <a href="mailto:${config.schoolSupportEmail}" target="_blank" rel="noopener noreferrer">${config.schoolSupportEmail}</a>.
        </p>
      `,
    },
  },
  survey: {
    getStarted: `
      <h2><strong>Welcome to the {{ surveySchoolYearNumber }} Creative Schools Survey!</strong></h2>
      <p>
        Through this annual survey, you will be asked to share information about arts education staffing and instruction at your school, partnerships with arts partners (i.e., arts organizations), and how your school budgets and plans for the arts.
      </p>
      <p>
        As you work on the {{ surveySchoolYearNumber }} survey, please be sure to frequently save your responses to each question to avoid losing information. You are not required to complete your survey in one session; saving your responses as you go will allow you to return at any time to complete or edit your survey before submitting.
      </p>
      <p>
        Please feel free to reach out to Diana Roofner at <a href="mailto:${config.schoolSupportEmail}" target="_blank" rel="noopener noreferrer">${config.schoolSupportEmail}</a> with any questions!
      </p>
    `,
    courses: {
      classMoniker: 'course',
      coursesNote: `
        <p>
          <strong>Note on courses:</strong> An arts course meets the following criteria: (1) it is offered to students during the school day, (2) it is categorized as an arts course rather than another content area (e.g., math, science), and (3) it is taught by a credited arts instructor.
        </p>
      `,
      minutesNote: `
        <p>
          <strong>Note on minutes:</strong> Please calculate average weekly minutes for elementary courses across the entire school year. If a course only meets for part of the year, include this adjustment in your estimate. For example:
        </p>
        <p>
          <ul class="bulleted-list">
            <li>A course that meets for 90 minutes per week in quarters 1 and 2, and not at all in quarters 3 and 4, would have an average of 45 weekly minutes. (90 / 2 = 45)</li>
            <li>A 120-minute course that meets once every three weeks across the school year would have an average of 40 weekly minutes. (120 / 3 = 40)</li>
            <li>A course that met for 90 minutes per week in semester 1 and 180 minutes per week in semester 2 would have an average of 135 weekly minutes. (90 / 2 + 180 / 2 = 135)</li>
          </ul>
        </p>
      `,
    },
    staffingQuestion: {
      modalMessage: `
        <p>
          <strong>Note on instructors</strong>: Restrict the list of instructors in your school to those teaching an arts course during the school day. Note that district-run/district-managed schools require arts instructors to hold a state certification or endorsement in one of the following arts disciplines: visual arts, media arts, music, theater, and/or dance. Charter schools, contract schools, and Pre-K-only schools do not have this requirement. If itinerant teachers teach at your school, please report those individuals as well.
        </p>
        <p>
          <strong>Note on part/full-time status</strong>: Please select Full Time if a teacher had full-time status at your school for the entire school year. If a teacher was part-time at your school for all or part of the year, or full-time for only part of the year, please select Part Time.
        </p>
        <p>
          <strong>Note on disciplines</strong>: The Primary Focus of each instructor is required. You may optionally list a Secondary Focus as well. Please also select the “Classroom/non-arts teacher?” button if the instructor is not primarily an arts instructor (e.g., non-arts, general education teacher teaching arts courses).
        </p>
      `,
      displayOptions: {
        showClassNumber: true,
      },
    },
    instructorQuestion: {
      displayOptions: {
        showInstructorEmail: true,
      },
    },
  },
}

// ----- SURVEY QUESTIONS CONFIG -----

const {
  APPROACHES,
  GOVERNANCE,
  OBSTACLES_SELECT,
  OBSTACLES_DESCRIBE,
  OBSTACLES_OVERCOME,
  DISTRICT_SPENDING,
  COMMUNITY_ENGAGEMENTS,
} = SURVEY_QUESTIONS

config.surveyQuestions = {
  approaches: APPROACHES.OPTIONS.PER_DISCIPLINE,
  governance: GOVERNANCE.OPTIONS.GENERAL,
  obstaclesSelect: OBSTACLES_SELECT.OPTIONS.GENERAL,
  obstaclesDescribe: OBSTACLES_DESCRIBE.OPTIONS.GENERAL,
  obstaclesOvercome: OBSTACLES_OVERCOME.OPTIONS.GENERAL,
  districtSpending: DISTRICT_SPENDING.OPTIONS.GENERAL,
  communityEngagement: COMMUNITY_ENGAGEMENTS.OPTIONS.GENERAL,
}

export default config
