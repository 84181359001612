import React, { useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Link } from 'react-router'
import { modifyProps, toggle } from 'lp-hoc'
import { Spinner } from 'lp-components'
import { InfoModal, SectionBlock } from 'components'
import {
  SurveyStepNav,
  ApproachesQuestion,
  ObstaclesQuestions,
  CommunityEnumerableQuestion,
  CustomQuestions,
  ScholarshipsQuestion,
  DynamicQuestion,
} from '../components'
import {
  ArtsInstructorForm,
  ProfessionalDevelopmentForm,
  ArtsSpaceForm,
  CoursesForm,
  ScheduleForm,
  PercentAccessForm,
  StandardsForm,
  ArtsIntegrationForm,
} from '../forms'
import { selectors as globalSchoolSelectors } from 'school-portal-reducer'
import { selectors as globalSelectors } from 'global-reducer'
import { selectors } from '../reducer'
import * as apiActions from 'api-actions'
import * as globalActions from 'school-portal-actions'
import * as Types from 'types'
import { first, sortBy, get, isEmpty } from 'lodash'
import { SubmissionError } from 'redux-form'
import {
  SCHOOL_PORTAL_ROUTE,
  COURSE_COURSES_NOTE,
  COURSE_ENROLLMENT_NOTE,
  COURSE_INSTRUCTIONAL_DEPTH_NOTE,
  COURSE_LISTINGS_NOTE,
  COURSE_MINUTES_NOTE,
  COURSES_VALIDATION_MESSAGE,
  NONZERO_VALIDATION_MESSAGE,
} from 'config'
import * as flashActions from 'redux-flash'
import {
  combineOtherOption,
  displaySubmitFailure,
  filterCoursesByQuestionCategory,
  filterDestroyed,
  filterQuestionsByPageAndDisplayComponent,
  findQuestionByPageAndDisplayComponent,
  getKThroughEightGradeOffered,
  getOtherTypeId,
  hasZeroMinutesInCourses,
  hasZeroStudentsEnrolled,
  isMissingMinutesInCourses,
  isMissingNumberOfStudentsEnrolled,
  isOtherType,
  replaceResources,
  useCommunity,
  useCommunityText,
  useDynamicInfoModal,
  yankBy,
} from 'utils'
import { scroller } from 'react-scroll'

const { APPROACHES, INTEGRATIONS } = Types.SURVEY_QUESTIONS

const propTypes = {
  school: Types.school.isRequired,
  updateSchool: PropTypes.func.isRequired,
  spaceTypes: PropTypes.arrayOf(Types.spaceType),
  integrationTypes: PropTypes.arrayOf(Types.integrationType).isRequired,
  scheduleTypes: PropTypes.arrayOf(Types.scheduleType),
  onSuccess: PropTypes.func.isRequired,
  definedDisciplines: PropTypes.arrayOf(Types.discipline).isRequired,
  approaches: PropTypes.arrayOf(Types.communityEnumerable).isRequired,
  standards: PropTypes.arrayOf(Types.communityEnumerable),
  participation: Types.participation,
  flashSuccessMessage: PropTypes.func.isRequired,
  fetchSpaceTypes: PropTypes.func.isRequired,
  fetchScheduleTypes: PropTypes.func.isRequired,
  fetchStandards: PropTypes.func.isRequired,
  surveySchoolYear: Types.schoolYear.isRequired,
  surveyQuestions: PropTypes.arrayOf(Types.surveyQuestion).isRequired,
}

const defaultProps = {}

function validateHighCourses(courses) {
  return courses.every((course) => course.instructionalDepth)
}

function validateKThroughEightCourses(courses) {
  const hasMissingMinutesInCourses = isMissingMinutesInCourses(courses)
  const hasMissingStudentsEnrolled = isMissingNumberOfStudentsEnrolled(courses)
  const hasZeroMinutes = hasZeroMinutesInCourses(courses)
  const hasZeroStudents = hasZeroStudentsEnrolled(courses)

  const hasBlankValue = hasMissingMinutesInCourses || hasMissingStudentsEnrolled
  const hasZeroValue = hasZeroMinutes || hasZeroStudents

  return { hasBlankValue, hasZeroValue }
}

function sortAndFilterCourses({ courses, questionCategory, isComboSchool }) {
  const sortedCourses = sortBy(courses, 'classNumber')
  if (!isComboSchool) return sortedCourses

  return sortedCourses.filter(
    ({ gradesOfferedTo }) => gradesOfferedTo === questionCategory
  )
}

function StaffingAndInstruction({
  onSuccess,
  definedDisciplines,
  approaches,
  standards,
  updateSchool,
  school,
  spaceTypes,
  integrationTypes,
  scheduleTypes,
  participation,
  fetchSpaceTypes,
  fetchScheduleTypes,
  fetchStandards,
  surveySchoolYear,
  surveyQuestions,
}) {
  useEffect(() => {
    fetchSpaceTypes()
    fetchScheduleTypes()
    fetchStandards()
  }, [])

  const questionsLoaded = !(
    !spaceTypes ||
    !scheduleTypes ||
    !definedDisciplines ||
    !standards
  )

  useEffect(() => {
    if (window.location.hash && questionsLoaded) {
      const elementId = window.location.hash.substring(1)

      scroller.scrollTo(elementId, { smooth: true })
    }
  }, [questionsLoaded])

  const [showInfoModal, infoModalContent, setShowInfoModalWithContent] =
    useDynamicInfoModal()

  const isHighSchool = school.category === Types.HIGH_SCHOOL_TYPE
  const isComboSchool = school.category === Types.COMBO_SCHOOL_TYPE

  const shouldShowElementaryMiddleForms = isComboSchool || !isHighSchool
  const shouldShowHighCourseForm = isComboSchool || isHighSchool

  const elementaryCourses = sortAndFilterCourses({
    courses: school.courses,
    questionCategory: Types.ELEMENTARY_SCHOOL_TYPE,
    isComboSchool,
  })
  const middleCourses = sortAndFilterCourses({
    courses: school.courses,
    questionCategory: Types.MIDDLE_SCHOOL_TYPE,
    isComboSchool,
  })
  const highCourses = sortAndFilterCourses({
    courses: school.courses,
    questionCategory: Types.HIGH_SCHOOL_TYPE,
    isComboSchool,
  })

  const [otherSpace, definedSpaces] = yankBy(school.spaces, isOtherType)

  const formatForSubmit = useCallback(
    (newSpaces) =>
      replaceResources({
        old: school.spaces,
        new: newSpaces,
      }),
    [school.spaces]
  )

  const kThroughEightGradeType = getKThroughEightGradeOffered(school.category)

  const surveySchoolYearNumber = surveySchoolYear.number

  const t = useCommunityText()
  const coursesNote = t('survey.courses.coursesNote') || COURSE_COURSES_NOTE
  const courseMinutesNote =
    t('survey.courses.minutesNote') || COURSE_MINUTES_NOTE
  const courseInstructionalDepthNote =
    t('survey.courses.instructionalDepthNote') ||
    COURSE_INSTRUCTIONAL_DEPTH_NOTE
  const hsCourseModalText =
    coursesNote +
    COURSE_ENROLLMENT_NOTE +
    courseInstructionalDepthNote +
    COURSE_LISTINGS_NOTE
  const esCourseModalText =
    coursesNote + courseMinutesNote + COURSE_ENROLLMENT_NOTE

  const community = useCommunity()
  const showQuestion = useCallback(
    (questionName, responseOptions) => {
      return (
        !!get(community, `surveyQuestions.${questionName}`) &&
        !isEmpty(responseOptions)
      )
    },
    [community]
  )

  if (!questionsLoaded) return <Spinner />

  const customQuestions = filterQuestionsByPageAndDisplayComponent(
    surveyQuestions,
    Types.SURVEY_PAGES.STAFFING_AND_INSTRUCTION,
    Types.QUESTION_DISPLAY_COMPONENTS.CUSTOM_QUESTION_FORM
  )

  const weeklyMinutesQuestion = findQuestionByPageAndDisplayComponent(
    surveyQuestions,
    Types.SURVEY_PAGES.STAFFING_AND_INSTRUCTION,
    Types.QUESTION_DISPLAY_COMPONENTS.WEEKLY_MINUTES_COURSE_FORM
  )

  const instructionalDepthQuestion = findQuestionByPageAndDisplayComponent(
    surveyQuestions,
    Types.SURVEY_PAGES.STAFFING_AND_INSTRUCTION,
    Types.QUESTION_DISPLAY_COMPONENTS.INSTRUCTIONAL_DEPTH_COURSE_FORM
  )

  const instructorsQuestion = findQuestionByPageAndDisplayComponent(
    surveyQuestions,
    Types.SURVEY_PAGES.STAFFING_AND_INSTRUCTION,
    Types.QUESTION_DISPLAY_COMPONENTS.ARTS_INSTRUCTOR_FORM
  )

  const scholarshipsQuestion = findQuestionByPageAndDisplayComponent(
    surveyQuestions,
    Types.SURVEY_PAGES.STAFFING_AND_INSTRUCTION,
    Types.QUESTION_DISPLAY_COMPONENTS.SCHOLARSHIPS_FORM
  )

  const standardsQuestion = findQuestionByPageAndDisplayComponent(
    surveyQuestions,
    Types.SURVEY_PAGES.STAFFING_AND_INSTRUCTION,
    Types.QUESTION_DISPLAY_COMPONENTS.STANDARDS_FORM
  )

  const courseScheduleQuestion = findQuestionByPageAndDisplayComponent(
    surveyQuestions,
    Types.SURVEY_PAGES.STAFFING_AND_INSTRUCTION,
    Types.QUESTION_DISPLAY_COMPONENTS.COURSE_SCHEDULE_FORM
  )

  return (
    <div>
      <SurveyStepNav currentStepIndex={0} />
      <div className="content-block-container">
        {shouldShowElementaryMiddleForms && weeklyMinutesQuestion && (
          <SectionBlock id="course-question">
            {isComboSchool && (
              <p>
                <strong>Elementary and Middle School Courses Only</strong>
              </p>
            )}
            <h2>{weeklyMinutesQuestion.body}</h2>
            <p>{weeklyMinutesQuestion.description}</p>
            {isComboSchool && (
              <p>
                Please report this information for elementary and middle school
                grade levels at your school.
              </p>
            )}
            <p>
              <button
                type="button"
                className="link-secondary"
                onClick={() => setShowInfoModalWithContent(esCourseModalText)}
              >
                Click here for additional information.
              </button>
            </p>
            <CoursesForm
              disciplines={definedDisciplines}
              initialValues={{
                courses: [...elementaryCourses, ...middleCourses],
              }}
              type={kThroughEightGradeType}
              isComboSchool={isComboSchool}
              onSubmit={updateSchool}
              onSubmitSuccess={(school) => {
                const courses = filterCoursesByQuestionCategory(
                  school.courses,
                  [Types.ELEMENTARY_SCHOOL_TYPE, Types.MIDDLE_SCHOOL_TYPE]
                )
                const { hasBlankValue, hasZeroValue } =
                  validateKThroughEightCourses(courses)
                if (hasBlankValue)
                  setShowInfoModalWithContent(COURSES_VALIDATION_MESSAGE)
                else if (hasZeroValue)
                  setShowInfoModalWithContent(NONZERO_VALIDATION_MESSAGE)
                onSuccess(school)
              }}
              onSubmitFail={displaySubmitFailure}
              readOnly={weeklyMinutesQuestion.readOnly}
              threshold={school.threshold}
            />
          </SectionBlock>
        )}
        {shouldShowHighCourseForm && instructionalDepthQuestion && (
          <SectionBlock>
            {isComboSchool && (
              <p>
                <strong>High School courses only</strong>
              </p>
            )}
            <h2>{instructionalDepthQuestion.body}</h2>
            <p>{instructionalDepthQuestion.description}</p>
            {isComboSchool && (
              <p>
                Please report this information for high school grade levels at
                your school.
              </p>
            )}
            <p>
              <button
                type="button"
                className="link-secondary"
                onClick={() => setShowInfoModalWithContent(hsCourseModalText)}
              >
                Click here for additional information.
              </button>
            </p>
            <CoursesForm
              disciplines={definedDisciplines}
              initialValues={{ courses: highCourses }}
              isHighSchool={true}
              isComboSchool={isComboSchool}
              onSubmit={updateSchool}
              type={Types.HIGH_SCHOOL_TYPE}
              onSubmitSuccess={(school) => {
                const courses = filterCoursesByQuestionCategory(
                  school.courses,
                  Types.HIGH_SCHOOL_TYPE
                )
                if (!validateHighCourses(courses))
                  setShowInfoModalWithContent(COURSES_VALIDATION_MESSAGE)
                onSuccess(school)
              }}
              onSubmitFail={displaySubmitFailure}
              readOnly={instructionalDepthQuestion.readOnly}
            />
          </SectionBlock>
        )}
        {instructorsQuestion && (
          <SectionBlock id="instructor-question">
            <h2>{instructorsQuestion.body}</h2>
            <p>{instructorsQuestion.description}</p>
            <p>
              <button
                type="button"
                className="link-secondary"
                onClick={() =>
                  setShowInfoModalWithContent(
                    t('survey.staffingQuestion.modalMessage')
                  )
                }
              >
                Click here for additional tips and guidelines for reporting arts
                teachers.
              </button>
            </p>
            {school.surveyDetail.maskPii && (
              <p>
                <em>
                  This information is for internal use only and will not be made
                  publicly available
                </em>
                .
              </p>
            )}
            <ArtsInstructorForm
              disciplines={definedDisciplines}
              initialValues={{
                instructors: sortBy(school.instructors, 'lastName'),
              }}
              onSubmit={updateSchool}
              onSubmitSuccess={onSuccess}
              onSubmitFail={displaySubmitFailure}
              readOnly={instructorsQuestion.readOnly}
            />
          </SectionBlock>
        )}
        {shouldShowElementaryMiddleForms && (
          <SectionBlock>
            {isComboSchool && (
              <p>
                <strong>Elementary and Middle School Courses Only</strong>
              </p>
            )}
            <h2>
              {t('survey.percentAccess.question', {
                surveySchoolYearNumber,
              }) ||
                `What percentage of students took at least one arts class in any discipline at your school in ${surveySchoolYearNumber}?`}
            </h2>
            <p>
              {t('survey.percentAccess.description') ||
                `Please provide the number of students at your school who completed at least one arts class during the school day and your school's total elementary and middle school student enrollment. We may have already obtained this information through student enrollment data from your local school district or state department of education. If this information is not already pre-populated, please use the blank space(s) below to report your school's percent access.`}
            </p>
            {isComboSchool && (
              <p>
                Please report this information for elementary and middle school
                grade levels at your school.
              </p>
            )}
            <PercentAccessForm
              initialValues={{ participation }}
              courseEnrollmentLabel={t(
                'survey.percentAccess.courseEnrollmentLabel'
              )}
              onSubmit={updateSchool}
              onSubmitSuccess={onSuccess}
              onSubmitFail={displaySubmitFailure}
            />
          </SectionBlock>
        )}
        {courseScheduleQuestion && (
          <SectionBlock>
            <h2>{courseScheduleQuestion.body}</h2>
            <ScheduleForm
              scheduleTypes={scheduleTypes}
              initialValues={{ schedules: school.schedules }}
              onSubmit={updateSchool}
              onSubmitSuccess={onSuccess}
              onSubmitFail={displaySubmitFailure}
            />
          </SectionBlock>
        )}
        {standardsQuestion && (
          <DynamicQuestion
            surveyQuestion={standardsQuestion}
            enumerableOptions={standards}
            setShowInfoModalWithContent={setShowInfoModalWithContent}
          >
            {(enumerableOptions, { ariaLabelledby }) => {
              return (
                <StandardsForm
                  ariaLabelledby={ariaLabelledby}
                  standardTypes={enumerableOptions}
                  initialValues={{ standard: first(school.standards) }}
                  onSubmit={({ standard }) => {
                    const standards = replaceResources({
                      old: school.standards,
                      new: [standard],
                    })
                    return updateSchool({ standards })
                  }}
                  onSubmitSuccess={onSuccess}
                  onSubmitFail={displaySubmitFailure}
                />
              )
            }}
          </DynamicQuestion>
        )}
        {showQuestion(APPROACHES.NAME, approaches) && (
          <SectionBlock>
            <ApproachesQuestion
              approachTypes={approaches}
              approaches={school.approaches}
              disciplines={definedDisciplines}
              onSubmit={updateSchool}
              onSubmitSuccess={onSuccess}
              onSubmitFail={displaySubmitFailure}
              setShowInfoModalWithContent={setShowInfoModalWithContent}
              surveySchoolYearNumber={surveySchoolYearNumber}
            />
          </SectionBlock>
        )}
        {showQuestion(INTEGRATIONS.NAME, integrationTypes) && (
          <SectionBlock>
            <CommunityEnumerableQuestion
              questionName={INTEGRATIONS.NAME}
              enumerableOptions={integrationTypes}
              setShowInfoModalWithContent={setShowInfoModalWithContent}
              surveySchoolYearNumber={surveySchoolYearNumber}
            >
              {(optionName) => {
                if (optionName === INTEGRATIONS.OPTIONS.GENERAL) {
                  return (
                    <ArtsIntegrationForm
                      integrationTypes={integrationTypes}
                      initialValues={{ integrations: school.integrations }}
                      onSubmit={updateSchool}
                      onSubmitSuccess={onSuccess}
                      onSubmitFail={displaySubmitFailure}
                    />
                  )
                }
              }}
            </CommunityEnumerableQuestion>
          </SectionBlock>
        )}
        <SectionBlock>
          <h2 id="prof-dev-question">
            Did any instructor or administrator have arts-specific professional
            development during the {surveySchoolYearNumber} school year?
          </h2>
          <p>
            Arts-specific professional development applies to any instructor at
            your school, not just arts instructors.
          </p>
          <ProfessionalDevelopmentForm
            initialValues={{ detail: school.surveyDetail }}
            onSubmit={updateSchool}
            onSubmitSuccess={onSuccess}
            onSubmitFail={displaySubmitFailure}
            ariaLabelledby="prof-dev-question"
          />
        </SectionBlock>
        <SectionBlock>
          <h2>
            What physical space did your school have in {surveySchoolYearNumber}{' '}
            that was dedicated to the arts? Check all that apply.
          </h2>
          <ArtsSpaceForm
            spaceTypes={spaceTypes}
            initialValues={{
              other: {
                ...otherSpace,
                spaceTypeId: getOtherTypeId(spaceTypes),
              },
              spaces: definedSpaces,
            }}
            beforeSubmit={({ other, spaces }) => {
              const resources = formatForSubmit(
                combineOtherOption(spaces, other)
              )
              if (isEmpty(filterDestroyed(resources)))
                throw new SubmissionError({
                  _error: 'You must select at least one option',
                })
              return {
                spaces: resources,
              }
            }}
            onSubmit={updateSchool}
            onSubmitSuccess={onSuccess}
            onSubmitFail={displaySubmitFailure}
          />
        </SectionBlock>
        <ObstaclesQuestions
          initialValues={{
            obstacles: school.obstacles,
          }}
          disciplines={definedDisciplines}
          onSubmit={updateSchool}
          onSubmitSuccess={onSuccess}
          onSubmitFail={displaySubmitFailure}
          surveySchoolYearNumber={surveySchoolYearNumber}
        />
        {scholarshipsQuestion && (
          <ScholarshipsQuestion
            question={scholarshipsQuestion}
            disciplines={definedDisciplines}
            scholarships={school.scholarships}
            onSubmit={updateSchool}
            onSubmitSuccess={onSuccess}
            onSubmitFail={displaySubmitFailure}
          />
        )}
        {!isEmpty(customQuestions) && (
          <CustomQuestions
            surveyQuestions={customQuestions}
            onSubmit={updateSchool}
            onSubmitSuccess={onSuccess}
            onSubmitFail={displaySubmitFailure}
            setShowInfoModalWithContent={setShowInfoModalWithContent}
            surveyAnswers={school.surveyAnswers}
          />
        )}
      </div>
      <div className="sticky-buttons">
        <div className="continue">
          <Link
            className="button-secondary"
            to={SCHOOL_PORTAL_ROUTE + '/survey/community-and-partnerships'}
          >
            Continue to Next Page
          </Link>
        </div>
      </div>
      {showInfoModal && (
        <InfoModal
          onClose={() => setShowInfoModalWithContent(false)}
          content={infoModalContent.content}
          header={infoModalContent.header}
        />
      )}
    </div>
  )
}

StaffingAndInstruction.propTypes = propTypes
StaffingAndInstruction.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    integrationTypes: selectors.integrationTypes(state),
    surveySchoolYear: globalSchoolSelectors.surveySchoolYear(state),
    scheduleTypes: selectors.scheduleTypes(state),
    spaceTypes: selectors.spaceTypes(state),
    definedDisciplines: globalSelectors.definedDisciplines(state),
    approaches: selectors.approaches(state),
    standards: selectors.standards(state),
    school: globalSchoolSelectors.school(state),
    participation: globalSchoolSelectors.surveyParticipation(state),
    surveyQuestions: selectors.surveyQuestions(state),
  }
}

const mapDispatchToProps = {
  flashSuccessMessage: flashActions.flashSuccessMessage,
  fetchSpaceTypes: apiActions.fetchSpaceTypes,
  updateSchool: apiActions.updateSchool,
  fetchScheduleTypes: apiActions.fetchScheduleTypes,
  fetchStandards: apiActions.fetchStandards,
  setSchool: globalActions.setSchool,
}

function modify({
  school,
  setSchool,
  flashSuccessMessage,
  surveySchoolYear,
  updateSchool,
}) {
  return {
    updateSchool: (params) =>
      updateSchool(school.id, surveySchoolYear.id, params),
    onSuccess: (school) => {
      setSchool(school)
      flashSuccessMessage('Responses saved!')
    },
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  modifyProps(modify),
  toggle('newArtsInstructorForm')
)(StaffingAndInstruction)
