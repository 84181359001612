import logo from 'images/communities/Chicago/logo.png'
import mapLogo from 'images/communities/Chicago/ingenuity-white.svg'
import dashboardHeaderImage from 'images/map/landing/home-hero.jpg'
import loginBackgroundImg from 'images/map/landing/kids-1.jpg'
import educatorLogo from 'images/communities/Chicago/Educator/educator-logo.svg'
import educatorSponsorLogo from 'images/communities/Chicago/Educator/cps-logo.png'
import favicon from 'images/communities/Chicago/favicon.png'
import homeLogo from 'images/home/community-logos/chicago.png'
import { SURVEY_QUESTIONS } from 'types'

const config = {
  subdomain: 'chicago',
  name: 'Chicago',
  position: 1,
  partnerSupportEmail: 'artlook@ingenuity-inc.org',
  schoolSupportEmail: 'artlook@ingenuity-inc.org',
  stylesheet: 'chicago.scss',
  mapRedirectPath: '',
  organizationUrl: 'https://www.ingenuity-inc.org/',
  faqUrl:
    'https://drive.google.com/file/d/1nzK784ubj_NtLWWGYFd3-2pv3PPrGYdI/view?usp=sharing',
  faqUrlEducator:
    'https://drive.google.com/file/d/1uV8TfCYVkQ0_SubVJc7t9t3w1DfHaRpp/view?usp=sharing',
  howToVideos:
    'https://www.youtube.com/watch?v=cOl0Lk1kjFs&list=PLYgCWzolmg3Uu2uFrUhMFuP9_gwgBgbWr',
  mapEnabled: true,
  schoolPortalEnabled: true,
  partnerPortalEnabled: true,
  partnerProgramsEnabled: true,
  educatorEnabled: true,
  notificationsEnabled: true,
  notificationListSize: 100,
  dataDashboardEnabled: false,
  id: 3,
}

// ----- SEARCH -----
config.search = {
  options: {
    blockList: [],
  },
  schoolSearchFilters: [
    'network',
    'creativeSchoolsCategory',
    'instructorsTeaching',
    'schoolType',
    'organizationPartnersCount',
    'programsOffered',
    'disciplineInterests',
    'resourceProgramInterests',
    'coursesOffered',
    'studentDemographics',
    'schoolDistrict',
    'openToPartnerships',
  ],
  map: {
    center: { lat: 41.878292, lng: -87.622578 },
    radius: 40, //in miles
  },
  directoryFilters: {
    blockList: [
      'coursesOffered',
      'creativeSchoolsCategory',
      'instructorsTeaching',
      'partnerProgramTypes',
      'partnersCount',
      'programTypes',
      'resourceProgramInterests',
      'resourceTypes',
      'schoolDistrict',
      'schoolTypes',
      'schoolYears',
      'studentDemographics',
      'subDisciplines',
      'schools',
      'topics',
      'users',
      'networks',
      'outcomeTypes',
      'financialAssistanceTypes',
      'openToPartnerships',
    ],
  },
  forumFilters: {
    blockList: [
      'coursesOffered',
      'creativeSchoolsCategory',
      'instructorsTeaching',
      'partnerProgramTypes',
      'partnersCount',
      'programTypes',
      'resourceProgramInterests',
      'resourceTypes',
      'schoolDistrict',
      'schoolYears',
      'studentDemographics',
      'subDisciplines',
      'gradesServed',
      'networks',
      'neighborhoods',
      'outcomeTypes',
      'financialAssistanceTypes',
      'openToPartnerships',
    ],
  },
}

// ----- BRANDING -----

config.defaultBranding = {
  className: 'default-community',
  favicon,
  mapLogo: {
    src: mapLogo,
    alt: "Ingenuity's homepage",
    link: config.organizationUrl,
  },
  homeLogo: {
    src: homeLogo,
    alt: 'Ingenuity',
  },
  communityLogo: {
    src: logo,
    alt: 'Artlook Schools',
  },
  dashboardHeader: {
    src: dashboardHeaderImage,
    alt: '',
  },
  loginBackgroundImg: {
    src: loginBackgroundImg,
    alt: '',
  },
  mapHero: {
    src: dashboardHeaderImage,
    alt: '',
  },
  educatorLogo: {
    src: educatorLogo,
    alt: "Chicago's artlook Educator",
  },
  educatorSponsorLogo: {
    src: educatorSponsorLogo,
    alt: 'Chicago Public Schools',
  },
}

// ----- DISPLAY TEXT -----

/**
 * This object overrides the display text that is defined globally with string values that are
 * specific to the organization. Mustache-esque templating is available for dynamic strings using
 * {{ insertFieldName }} for interpolation.
 */

config.displayText = {
  map: {
    title: 'artlook Map: Increasing Arts Education in Chicago Public Schools',
    searchHeader: 'Find Schools & Partners in Chicago',
    cscLinkText: 'Creative Schools Certification Roadmap',
    welcomeSearchHeader: 'Explore Arts Education in Chicago',
    welcomeHeader: `artlook<sup>®</sup> Chicago`,
    welcomeMessage: `
      <p>
        artlook<sup>®</sup> is your one-stop-shop for information on arts education in our city. With thousands of data points, artlook<sup>®</sup> provides the information you need to make strategic decisions about expanding the arts in Chicago Public Schools.
      </p>
      <p>
        Developed in close coordination with Chicago's arts educators, this platform can show you everything from which art teachers and arts partners are in which schools to what arts programming, coursework, and minutes of instruction is happening where.
      </p>
      <p>
        Start exploring artlook<sup>®</sup> today, and help us expand arts education access, quality, and equity for every child, in every grade, in every school.
      </p>
    `,
  },
  tagline:
    "Let's increase arts education access, equity, and quality in Chicago Public Schools.",
  partnerPortal: {
    dashboard: {
      welcomeMessage: `
        <h3><em>artlook<sup>®</sup> Chicago</em> is YOUR gallery for arts education data</h3>
        <p>
          <em>artlook<sup>®</sup> Chicago</em> is the only platform of its kind that combines data from Chicago-area public schools and arts education program providers (like you!). The goal is to build direct connections between arts providers and schools to expand equitable access to quality arts education programming for EVERY student in our community.
        </p>
        <p><strong>To get started, follow the links in the boxes below to share your organization's program and school partnership information.</strong></p>
        <p>If you have any questions or need assistance, please contact <a href="mailto:${config.partnerSupportEmail}" target="_blank" rel="noopener noreferrer">${config.partnerSupportEmail}</a>.</p>
      `,
    },
  },
  schoolPortal: {
    dashboard: {
      welcomeMessage: `
        <h3><em>artlook<sup>®</sup> Chicago</em> is YOUR gallery for arts education data</h3>
        <p>
          <em>artlook<sup>®</sup> Chicago</em> is the only platform of its kind that combines data from Chicago-area public schools (like you!) and arts education program providers. The goal is to build direct connections between arts providers and schools to expand equitable access to quality arts education programming for EVERY student in our community.
        </p>
        <p>
          <strong>
            To get started, follow the link below to complete a brief survey about your school's arts education offerings and partnerships.
          </strong>
        </p>
        <p>If you have any questions or need assistance, please contact <a href="mailto:${config.schoolSupportEmail}" target="_blank" rel="noopener noreferrer">${config.schoolSupportEmail}</a>.</p>
      `,
    },
  },
  educatorPortal: {
    welcomeMessage: `
        <h3>Welcome to Chicago's <em>artlook<sup>®</sup></em> Educator!</h3>
        <p>
          Find, Connect, and Collaborate with your Colleagues!
        </p>
      `,
  },
  survey: {
    getStarted: `<h2>Welcome to the {{ surveySchoolYearNumber }} Creative Schools Survey!</h2>
    <p>Through this annual survey, you will be asked to share information about arts education staffing and instruction at your school, partnerships with arts partners (i.e. arts organizations), and how your school budgets and plans for the arts.</p>
    <p>As you work on the {{ surveySchoolYearNumber }} survey, please be sure to frequently save your responses to each question to avoid losing information. You are not required to complete your survey in one session; saving your responses as you go will allow you to return at any time to complete or edit your survey before submitting.</p>
    <p>Please feel free to reach out to <a href="mailto:${config.schoolSupportEmail}" target="_blank" rel="noopener noreferrer">${config.schoolSupportEmail}</a> with any questions!</p>`,
    staffingQuestion: {
      modalMessage: `
        <p>
        <strong>Note on instructors:</strong> Restrict the list of instructors in your school to those teaching an arts course during the school day. Note that district-run/district-managed schools require arts instructors to hold an ISBE certification or endorsement in one of the four state-recognized disciplines (visual arts, music, theater, and/or dance). Charter schools, contract schools, and Pre-K-only schools do not have this requirement. The media arts discipline does not have an ISBE certification/endorsement; these instructors should be listed as well.
        </p>
        <p>
        <strong>Note on part/full-time status:</strong> Please select Full Time if a teacher had full-time status for the entire school year. If a teacher was part-time for all or part of the year, or full-time for only part of the year, please select Part Time.
        </p>
        <p>
        <strong>Note on disciplines:</strong> The Primary Focus of each instructor is required. You may optionally list a Secondary Focus as well. Please also select the “Classroom/non-arts teacher?” button if the instructor is not primarily an arts instructor (e.g., non-arts, general education teacher teaching arts courses).
        </p>
        `,
      displayOptions: {
        showClassNumber: true,
      },
    },
    instructorQuestion: {
      displayOptions: {
        showInstructorEmail: true,
      },
    },
  },
}

// ----- SURVEY QUESTIONS CONFIG -----

const {
  APPROACHES,
  GOVERNANCE,
  OBSTACLES_DESCRIBE,
  OBSTACLES_OVERCOME,
  DISTRICT_SPENDING,
  COMMUNITY_ENGAGEMENTS,
} = SURVEY_QUESTIONS

config.surveyQuestions = {
  approaches: APPROACHES.OPTIONS.GENERAL,
  governance: GOVERNANCE.OPTIONS.GENERAL,
  obstaclesDescribe: OBSTACLES_DESCRIBE.OPTIONS.GENERAL,
  obstaclesOvercome: OBSTACLES_OVERCOME.OPTIONS.GENERAL,
  districtSpending: DISTRICT_SPENDING.OPTIONS.GENERAL,
  communityEngagement: COMMUNITY_ENGAGEMENTS.OPTIONS.GENERAL,
}

// ----- EDUCATOR CONFIG -----

config.educator = {
  communityOfPracticeEnabled: true,
}

export default config
