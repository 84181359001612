import logo from 'images/communities/NewOrleans/logo.svg'
import dashboardHeaderImage from 'images/communities/NewOrleans/banner.jpg'
import loginBackgroundImg from 'images/communities/NewOrleans/background-image.jpg'
import mapHeaderImage from 'images/communities/NewOrleans/landing.jpg'
import favicon from 'images/communities/NewOrleans/favicon.jpg'
import homeLogo from 'images/home/community-logos/new-orleans.png'
import { SURVEY_QUESTIONS } from 'types'

const config = {
  subdomain: 'neworleans',
  name: 'New Orleans',
  position: 4,
  partnerSupportEmail: 'artlooknola@artsedall.org',
  schoolSupportEmail: 'artlooknola@artsedall.org',
  stylesheet: 'new-orleans.scss',
  mapRedirectPath: '',
  organizationUrl: 'https://artsedall.org',
  faqUrl:
    'https://drive.google.com/file/d/1D4jBafcHqNqb37qTVdZuEjPXCCl3A1UX/view?usp=sharing',
  mapEnabled: true,
  schoolPortalEnabled: true,
  partnerPortalEnabled: true,
  partnerProgramsEnabled: true,
  educatorEnabled: false,
  notificationsEnabled: true,
  notificationListSize: -1,
  dataDashboardEnabled: true,
  id: 9,
}

// ----- SEARCH -----
config.search = {
  options: {
    blockList: ['networks', 'creativeSchoolsCategory', 'schoolDistrict'],
  },
  schoolSearchFilters: [
    'network',
    'creativeSchoolsCategory',
    'instructorsTeaching',
    'schoolType',
    'organizationPartnersCount',
    'programsOffered',
    'disciplineInterests',
    'resourceProgramInterests',
    'coursesOffered',
    'studentDemographics',
    'schoolDistrict',
    'openToPartnerships',
  ],
  map: {
    center: { lat: 30.0217523, lng: -89.9290514 },
  },
}

// ----- BRANDING -----

config.defaultBranding = {
  className: 'nola-community',
  favicon,
  mapLogo: {
    src: logo,
    alt: "New Orleans Arts Education Alliance's homepage",
    link: config.organizationUrl,
  },
  homeLogo: {
    src: homeLogo,
    alt: 'New Orleans Arts Education Alliance',
  },
  communityLogo: {
    src: logo,
    alt: 'Artlook Schools',
  },
  dashboardHeader: {
    src: dashboardHeaderImage,
    alt: '',
  },
  loginBackgroundImg: {
    src: loginBackgroundImg,
    alt: '',
  },
  mapHero: {
    src: mapHeaderImage,
    alt: '',
  },
}

// ----- DISPLAY TEXT -----

/**
 * This object overrides the display text that is defined globally with string values that are
 * specific to the organization. Mustache-esque templating is available for dynamic strings using
 * {{ insertFieldName }} for interpolation.
 */

config.displayText = {
  map: {
    title: 'artlook Map: Increasing Arts Education in New Orleans',
    searchHeader: 'Find Schools & Partners in New Orleans',
    welcomeSearchHeader: 'Explore Arts Education in New Orleans',
    welcomeHeader: `artlook<sup>®</sup> New Orleans`,
    welcomeMessage: `
      <p>artlook<sup>®</sup> is your one-stop-shop for information on arts education in our city. With thousands of data points, artlook<sup>®</sup> provides the information you need to make strategic decisions about expanding the arts in New Orleans.</p>

      <p>Developed in close coordination with New Orleans’ arts educators, this platform can show you everything from which art teachers and arts partners are in which schools to what arts programming, coursework, and minutes of instruction is happening where.</p>

      <p>Start exploring artlook<sup>®</sup> today, and help us expand arts education access, quality, and equity for every child, in every grade, in every school.</p>
    `,
  },
  tagline:
    "Let's increase arts education access, equity, and quality in New Orleans.",
  partnerPortal: {
    dashboard: {
      welcomeMessage: `
        <h3><em>artlook<sup>®</sup> New Orleans</em> is YOUR local hub for arts education</h3>
        <p>
          <em>artlook<sup>®</sup> New Orleans</em> is a unique, searchable platform that combines data from Orleans Parish public schools and arts education program providers (like you!) across the city. The goal is to increase partnerships and resources for arts providers and schools, and expand equitable access to quality arts education for EVERY student in our community.
        </p>
        <p>
          <strong>To get started, follow the links in the boxes below to share your organization's program and school partnership information.</strong>
        </p>
        <p>
          If you have any questions or need assistance, please contact <a href="mailto:${config.partnerSupportEmail}" target="_blank" rel="noopener noreferrer">${config.partnerSupportEmail}</a>.
        </p>
      `,
    },
  },
  schoolPortal: {
    dashboard: {
      welcomeMessage: `
        <h3><em>artlook<sup>®</sup> New Orleans</em> is YOUR local hub for arts education</h3>
        <p>
          <em>artlook<sup>®</sup> New Orleans</em> is a unique, searchable platform that combines data from Orleans Parish public schools (like yours!) and arts education program providers across the city. The goal is to increase partnerships and resources for arts providers and schools, and expand equitable access to quality arts education for EVERY student in our community.
        </p>
        <p>
          <strong>To get started, follow the links in the boxes below to share your school's program and partnership information.</strong>
        </p>
        <p>
          If you have any questions or need assistance, please contact <a href="mailto:${config.schoolSupportEmail}" target="_blank" rel="noopener noreferrer">${config.schoolSupportEmail}</a>.
        </p>
      `,
    },
  },
  survey: {
    getStarted: `
      <h2>Welcome to the {{ surveySchoolYearNumber }} artlook<sup>®</sup> Survey!</h2>
      <p>
        Through this annual survey, you will be asked to share information about arts education staffing and instruction at your school, partnerships with arts partners (i.e. arts organizations), and how your school budgets and plans for the arts.
      </p>
      <p>
        <strong>Because of learning disruptions and differing instructional formats in the 2019-20 school year we ask you to <em>only consider courses, programs, and arts instruction from the beginning of the school year through the closure of in-person instruction in March.</em></strong>
      </p>
      <p>
        As you work on the {{ surveySchoolYearNumber }} survey, please be sure to save your responses to each question to avoid losing information. You are not required to complete your survey in one session; so long as your question responses are saved, you can return at any time to edit your survey before submitting.
      </p>
      <p>
        Please feel free to reach out to <a href="mailto:${config.schoolSupportEmail}" target="_blank" rel="noopener noreferrer">${config.schoolSupportEmail}</a> with any questions.
      </p>
    `,
    staffingQuestion: {
      modalMessage: `
        <p>
          <strong>Note on instructors</strong>: Restrict the list of instructors in your school to those teaching an arts course during the school day. If itinerant teachers teach at your school, please report those individuals as well. If your school employs teaching artists as instructors, please report those individuals in the Community & Partnerships section of this survey.
        </p>
        <p>
          <strong>Note on part/full-time status</strong>: Please select Full Time if a teacher had full-time status at your school for the entire school year. If a teacher was part-time at your school for all or part of the year, or full-time for only part of the year, please select Part Time.
        </p>
        <p>
          <strong>Note on disciplines</strong>: The Primary Focus of each instructor is required. You may optionally list a Secondary Focus as well. Please also select the “Classroom/non-arts teacher?” button if the instructor is not primarily an arts instructor (e.g., non-arts, general education teacher teaching arts courses).
        </p>
      `,
      displayOptions: {
        showClassNumber: true,
      },
    },
    instructorQuestion: {
      displayOptions: {
        showInstructorEmail: true,
      },
    },
  },
}

// ----- SURVEY QUESTIONS CONFIG -----

const {
  APPROACHES,
  OBSTACLES_SELECT,
  OBSTACLES_DESCRIBE,
  OBSTACLES_OVERCOME,
  COMMUNITY_ENGAGEMENTS,
} = SURVEY_QUESTIONS

config.surveyQuestions = {
  approaches: APPROACHES.OPTIONS.PER_DISCIPLINE,
  obstaclesSelect: OBSTACLES_SELECT.OPTIONS.GENERAL,
  obstaclesDescribe: OBSTACLES_DESCRIBE.OPTIONS.GENERAL,
  obstaclesOvercome: OBSTACLES_OVERCOME.OPTIONS.GENERAL,
  communityEngagement: COMMUNITY_ENGAGEMENTS.OPTIONS.GENERAL,
}

export default config
