import React, { useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Link } from 'react-router'
import { SpinnerButton, SectionBlock } from 'components'
import { SurveyStepNav } from '../components'
import * as apiActions from 'api-actions'
import * as actions from '../actions'
import { selectors as globalSelectors } from 'school-portal-reducer'
import { selectors } from '../reducer'
import * as Types from 'types'
import { SCHOOL_PORTAL_ROUTE, SURVEY_ATTEMPT_STATES } from 'config'
import back from 'images/back.svg'
import * as routerActions from 'react-router-redux'
import { get, isNil, isNumber, isEmpty } from 'lodash'
import { format } from 'date-fns'
import {
  convertSurveyPageNameToNumber,
  filterCoursesByQuestionCategory,
  hasZeroMinutesInCourses,
  hasZeroStudentsEnrolled,
  isMissingMinutesInCourses,
  isMissingNumberOfStudentsEnrolled,
  useCommunitySurveyQuestionOption,
  useCommunityText,
} from 'utils'

const propTypes = {
  surveyAttempt: Types.surveyAttempt,
  setSurveyAttemptState: PropTypes.func.isRequired,
  completeSurveyAttempt: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  school: Types.school.isRequired,
  participation: Types.participation,
  surveyQuestions: PropTypes.arrayOf(Types.surveyQuestion).isRequired,
  approaches: PropTypes.arrayOf(Types.communityEnumerable).isRequired,
  integrationTypes: PropTypes.arrayOf(Types.integrationType).isRequired,
  governanceTypes: PropTypes.arrayOf(Types.communityEnumerable).isRequired,
  fetchSurveyAttemptReview: PropTypes.func.isRequired,
  surveyAttemptReview: Types.surveyAttemptReview,
}

const defaultProps = {
  surveyAttempt: null,
  participation: null,
  surveyAttemptReview: null,
}

const { APPROACHES, INTEGRATIONS, GOVERNANCE, DISTRICT_SPENDING } =
  Types.SURVEY_QUESTIONS

const { REQUIRED, OPTIONAL } = Types.SURVEY_QUESTIONS_NECESSITIES

const {
  INSTRUCTIONAL_DEPTH_COURSE_FORM,
  WEEKLY_MINUTES_COURSE_FORM,
  ARTS_INSTRUCTOR_FORM,
  STANDARDS_FORM,
  FUNDING_FORM,
  CUSTOM_QUESTION_FORM,
} = Types.QUESTION_DISPLAY_COMPONENTS

function validateCourseInstructionalDepth(school) {
  const filteredCourses = filterCoursesByQuestionCategory(
    school.courses,
    Types.HIGH_SCHOOL_TYPE
  )

  return filteredCourses.every((course) => course.instructionalDepth)
}

function validateCourseWeeklyMinutes(school) {
  const filteredCourses = filterCoursesByQuestionCategory(school.courses, [
    Types.ELEMENTARY_SCHOOL_TYPE,
    Types.MIDDLE_SCHOOL_TYPE,
  ])

  const hasMissingMinutes = isMissingMinutesInCourses(filteredCourses)
  const hasMissingStudents = isMissingNumberOfStudentsEnrolled(filteredCourses)
  const hasZeroMinutes = hasZeroMinutesInCourses(filteredCourses)
  const hasZeroStudents = hasZeroStudentsEnrolled(filteredCourses)

  return (
    hasMissingMinutes || hasMissingStudents || hasZeroMinutes || hasZeroStudents
  )
}

function validateCoursePresence(school, schoolCategories) {
  const filteredCourses = filterCoursesByQuestionCategory(
    school.courses,
    schoolCategories
  )
  return !isEmpty(filteredCourses)
}

function validateInstructorsSchedule(school) {
  return school.instructors.every((instructor) => instructor.schedule)
}

function validatePercentAccess(school, participation) {
  if (school.category === Types.HIGH_SCHOOL_TYPE) return true
  return participation && isNumber(participation.courseEnrollment)
}

function validateCustomAnswer(school, questionId) {
  return school.surveyAnswers.some(
    (answer) => answer.surveyQuestionId === questionId
  )
}

function dynamicQuestionError(question) {
  return `${question.body} (${convertSurveyPageNameToNumber(
    question.surveyPage
  )})`
}

function getSubmitErrors(
  school,
  participation,
  validateCustomQuestion,
  getCustomMessage,
  communityText
) {
  const errors = []

  if (!validatePercentAccess(school, participation))
    errors.push(
      getCustomMessage('survey.percentAccess.error') ||
        'What percentage of students took at least one arts class in any discipline at your school? (Page 1)'
    )
  const approachesError = validateCustomQuestion(APPROACHES.NAME)
  if (approachesError) {
    errors.push(approachesError)
  }
  const integrationsError = validateCustomQuestion(INTEGRATIONS.NAME)
  if (integrationsError) {
    errors.push(integrationsError)
  }
  if (isNil(school.surveyDetail.professionalDevelopment))
    errors.push(
      'Did any instructor or administrator have arts-specific professional development during the school year? (Page 1)'
    )
  if (isEmpty(school.spaces))
    errors.push(
      'What physical space did your school have that was dedicated to the arts? (Page 1)'
    )
  if (isEmpty(school.engagements))
    errors.push(
      communityText('survey.communityEngagement.question') ||
        'Please select all opportunities your school had in ' +
          '2023 for engagement in the arts by parents, guardians, families, and/or the community (Page 2)'
    )
  const governancesError = validateCustomQuestion(GOVERNANCE.NAME)
  if (governancesError) {
    errors.push(governancesError)
  }
  const districtSpendingError = validateCustomQuestion(DISTRICT_SPENDING.NAME)
  if (districtSpendingError) errors.push(districtSpendingError)

  return errors
}

function getSubmitWarnings(school) {
  const warnings = []

  if (isEmpty(school.partnerships)) {
    warnings.push(
      'Which arts organizations / teaching artists did your school partner with? (Page 2)'
    )
  }

  return warnings
}

function getOutlierWarnings(review) {
  const outlierWarningMessages = []

  if (!review) return outlierWarningMessages

  if (review.coursesWithNoInstructors) {
    outlierWarningMessages.push({
      text: "You haven't listed any instructors for the arts course(s) at your school.",
      link:
        SCHOOL_PORTAL_ROUTE +
        '/survey/staffing-and-instruction#instructor-question',
    })
  }

  if (review.instructorsWithNoCourses) {
    outlierWarningMessages.push({
      text: "You haven't listed any courses for the arts instructor(s) at your school.",
      link:
        SCHOOL_PORTAL_ROUTE +
        '/survey/staffing-and-instruction#course-question',
    })
  }

  return outlierWarningMessages
}

function ReviewAndSubmit({
  fetchSurveyAttemptReview,
  participation,
  setSurveyAttemptState,
  push,
  surveyAttempt,
  school,
  completeSurveyAttempt,
  surveyQuestions,
  approaches,
  integrationTypes,
  governanceTypes,
  surveyAttemptReview,
}) {
  useEffect(() => {
    if (!surveyAttempt) return

    fetchSurveyAttemptReview(surveyAttempt.id)
  }, [surveyAttempt.id])

  const customQuestionResponseOption = {
    approaches,
    integrations: integrationTypes,
    governances: governanceTypes,
  }

  const validateCustomQuestion = useCallback(
    (questionName) => {
      const questionOption = useCommunitySurveyQuestionOption(questionName)
      if (!questionOption) return

      const { error, responseType } = questionOption
      const responseOptions = customQuestionResponseOption[responseType]
      if (responseOptions && isEmpty(responseOptions)) return

      const throwError = error && isEmpty(get(school, responseType))
      if (!throwError) return

      return error
    },
    [school, useCommunitySurveyQuestionOption]
  )

  const t = useCommunityText()
  const getCustomMessage = useCallback(
    (messageKey) => {
      return t(messageKey)
    },
    [t]
  )

  let submitErrors = getSubmitErrors(
    school,
    participation,
    validateCustomQuestion,
    getCustomMessage,
    t
  )

  let submitWarnings = getSubmitWarnings(school)

  const outlierWarnings = getOutlierWarnings(surveyAttemptReview)

  surveyQuestions.forEach((question) => {
    if (question.necessity === OPTIONAL) return
    let messages =
      question.necessity === REQUIRED ? submitErrors : submitWarnings

    if (
      question.displayComponent === INSTRUCTIONAL_DEPTH_COURSE_FORM &&
      !validateCoursePresence(school, [Types.HIGH_SCHOOL_TYPE])
    ) {
      // Always set a warning if question is blank
      submitWarnings.push(dynamicQuestionError(question))
      return
    }
    if (
      question.displayComponent === INSTRUCTIONAL_DEPTH_COURSE_FORM &&
      !validateCourseInstructionalDepth(school)
    ) {
      messages.push(dynamicQuestionError(question))
      return
    }
    if (
      question.displayComponent === WEEKLY_MINUTES_COURSE_FORM &&
      !validateCoursePresence(school, [
        Types.ELEMENTARY_SCHOOL_TYPE,
        Types.MIDDLE_SCHOOL_TYPE,
      ])
    ) {
      // Always set a warning if question is blank
      submitWarnings.push(dynamicQuestionError(question))
      return
    }
    if (
      question.displayComponent === WEEKLY_MINUTES_COURSE_FORM &&
      validateCourseWeeklyMinutes(school)
    ) {
      messages.push(dynamicQuestionError(question))
      return
    }
    if (
      question.displayComponent === ARTS_INSTRUCTOR_FORM &&
      isEmpty(school.instructors)
    ) {
      // Always set a warning if question is blank
      submitWarnings.push(dynamicQuestionError(question))
      return
    }
    if (
      question.displayComponent === ARTS_INSTRUCTOR_FORM &&
      !validateInstructorsSchedule(school)
    ) {
      messages.push(dynamicQuestionError(question))
      return
    }
    if (
      question.displayComponent === STANDARDS_FORM &&
      isEmpty(school.standards)
    ) {
      messages.push(dynamicQuestionError(question))
      return
    }
    if (
      question.displayComponent === FUNDING_FORM &&
      isEmpty(school.fundings)
    ) {
      messages.push(dynamicQuestionError(question))
      return
    }
    if (
      question.displayComponent === CUSTOM_QUESTION_FORM &&
      !validateCustomAnswer(school, question.id)
    ) {
      messages.push(dynamicQuestionError(question))
      return
    }
  })

  const expirationDate = format(
    get(surveyAttempt, 'survey.dueDate'),
    'dddd, MMMM D'
  )

  return (
    <div>
      <SurveyStepNav currentStepIndex={3} />
      <div className="content-block-container">
        {!isEmpty(submitErrors) && (
          <SectionBlock>
            <h2 className="full-error-message"> Your survey is incomplete </h2>
            <p>
              Please provide responses to the following questions before
              submitting your survey.
            </p>
            <div className="submit-errors">
              <ul>
                {submitErrors.map((err, i) => (
                  <li key={i}>
                    <strong>{err}</strong>
                  </li>
                ))}
              </ul>
            </div>
          </SectionBlock>
        )}
        {!isEmpty(submitWarnings) && (
          <SectionBlock className="warning-message warning">
            <h2>Warning: you did not provide responses to some questions</h2>
            <p>
              You did not provide responses to the questions below. If this was
              an error, please go back and respond to these questions. If the
              omission was intentional or if the question does not apply to your
              school, then you may submit your survey.
            </p>
            <div className="submit-errors">
              <ul>
                {submitWarnings.map((err, i) => (
                  <li key={i}>
                    <strong>{err}</strong>
                  </li>
                ))}
              </ul>
            </div>
          </SectionBlock>
        )}
        {!isEmpty(outlierWarnings) && (
          <SectionBlock className="warning-message alert">
            <h2>Warning: Outlier Values</h2>
            <p>
              Some entries you recorded were outside the normal range of
              responses we see. If these entries are correct please disregard
              this warning and submit your survey once complete. Thank you!
            </p>
            <div className="submit-errors">
              <ul>
                {outlierWarnings.map((outlierWarning) => (
                  <li key={outlierWarning.text}>
                    <strong>
                      {outlierWarning.text}{' '}
                      {outlierWarning.link && (
                        <Link to={outlierWarning.link}>[Review entries]</Link>
                      )}
                    </strong>
                  </li>
                ))}
              </ul>
            </div>
          </SectionBlock>
        )}
        <SectionBlock>
          <h2>Submit Survey</h2>
          <p>
            <strong>Surveys must be submitted by {expirationDate}</strong>
          </p>
          <p>
            You may edit your responses at any time until the survey deadline,
            so long as you haven't already submitted your survey. If you've
            already submitted your survey or if it is past the deadline, then
            all answers will be locked and the survey will be closed.
          </p>
          <hr />
          <p>
            By clicking the "Submit Survey" button below, you acknowledge that
            you have reviewed all your responses and verify that all information
            is correct.
          </p>
        </SectionBlock>
      </div>
      <div className="sticky-buttons">
        <Link
          className="link-black"
          to={SCHOOL_PORTAL_ROUTE + '/survey/budget-and-planning'}
        >
          <img src={back} alt="" /> Back
        </Link>
        <div className="continue">
          <SpinnerButton
            type="button"
            className="button-secondary"
            invalid={!isEmpty(submitErrors)}
            onClick={() => {
              if (
                !confirm(
                  `Are you sure? Answers cannot be changed after submission.`
                )
              )
                return
              return completeSurveyAttempt(surveyAttempt.id)
                .then(setSurveyAttemptState(SURVEY_ATTEMPT_STATES.COMPLETE))
                .then(() => push(SCHOOL_PORTAL_ROUTE + '/survey'))
            }}
          >
            Submit Survey
          </SpinnerButton>
        </div>
      </div>
    </div>
  )
}

ReviewAndSubmit.propTypes = propTypes

ReviewAndSubmit.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    school: globalSelectors.school(state),
    surveyAttempt: selectors.surveyAttempt(state),
    participation: globalSelectors.surveyParticipation(state),
    surveyQuestions: selectors.surveyQuestions(state),
    approaches: selectors.approaches(state),
    integrationTypes: selectors.integrationTypes(state),
    governanceTypes: selectors.governanceTypes(state),
    surveyAttemptReview: selectors.surveyAttemptReview(state),
  }
}

const mapDispatchToProps = {
  setSurveyAttemptState: actions.setSurveyAttemptState,
  completeSurveyAttempt: apiActions.completeSurveyAttempt,
  push: routerActions.push,
  fetchSurveyAttemptReview: apiActions.fetchSurveyAttemptReview,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  ReviewAndSubmit
)
