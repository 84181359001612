import logo from 'images/communities/Jacksonville/logo.svg'
import dashboardHeaderImage from 'images/communities/Jacksonville/header-banner3.png'
import loginBackgroundImg from 'images/communities/Jacksonville/background-image4.png'
import mapHeaderImage from 'images/communities/Jacksonville/landing2.png'
import favicon from 'images/communities/Jacksonville/favicon.ico'
import homeLogo from 'images/home/community-logos/jacksonville.png'
import { SURVEY_QUESTIONS } from 'types'

const config = {
  subdomain: 'jacksonville',
  name: 'Jacksonville',
  position: 2,
  partnerSupportEmail: 'LEAD@capkids.org',
  schoolSupportEmail: 'LEAD@capkids.org',
  stylesheet: 'jax.scss',
  mapRedirectPath: '',
  organizationUrl: 'https://capkids.org/lead/',
  faqUrl:
    'https://drive.google.com/file/d/1neOF6qNfU5wNY21uUPk-n3LofJ9hRiyi/view?usp=sharing',
  mapEnabled: false,
  schoolPortalEnabled: false,
  partnerPortalEnabled: false,
  partnerProgramsEnabled: false,
  educatorEnabled: false,
  notificationsEnabled: true,
  notificationListSize: -1,
  dataDashboardEnabled: true,
  id: 4,
}

// ----- SEARCH -----
config.search = {
  options: {
    blockList: ['networks', 'creativeSchoolsCategory', 'coursesOffered'],
  },
  schoolSearchFilters: [
    'network',
    'creativeSchoolsCategory',
    'instructorsTeaching',
    'schoolType',
    'organizationPartnersCount',
    'programsOffered',
    'disciplineInterests',
    'resourceProgramInterests',
    'coursesOffered',
    'studentDemographics',
    'schoolDistrict',
    'openToPartnerships',
  ],
  map: {
    center: { lat: 30.3117131, lng: -81.6627049 },
  },
}

// ----- BRANDING -----

config.defaultBranding = {
  className: 'jax-community',
  favicon,
  mapLogo: {
    src: logo,
    alt: "LEAD's homepage",
    link: config.organizationUrl,
  },
  homeLogo: {
    src: homeLogo,
    alt: 'LEAD - Landscape of Education in the Arts in Duval',
  },
  communityLogo: {
    src: logo,
    alt: 'LEAD - Landscape of Education in the Arts in Duval',
  },
  dashboardHeader: {
    src: dashboardHeaderImage,
    alt: '',
  },
  loginBackgroundImg: {
    src: loginBackgroundImg,
    alt: '',
  },
  mapHero: {
    src: mapHeaderImage,
    alt: '',
  },
}

// ----- DISPLAY TEXT -----

/**
 * This object overrides the display text that is defined globally with string values that are
 * specific to the organization. Mustache-esque templating is available for dynamic strings using
 * {{ insertFieldName }} for interpolation.
 */

config.displayText = {
  map: {
    title:
      'artlook Map: Increasing Arts Education in Duval County Public Schools',
    searchHeader: 'Find Schools & Partners in Duval County',
    welcomeSearchHeader: 'Explore Arts Education in Duval County',
    welcomeHeader: `LEAD: Landscape of Education in the Arts in Duval (powered by artlook<sup>®</sup>)`,
    welcomeMessage: `
      <p>
        The LEAD artlook<sup>®</sup> map is a one-stop-shop for information on arts education in Jacksonville. Our community has made incredible strides toward equitable access to quality arts education for all children, but without reliable data it is difficult to understand the impact, equity and standards of arts learning.
      </p>
      <p>
        Developed in close coordination with arts educators, administrators and cultural organizations locally and nationwide, the LEAD artlook<sup>®</sup> map identifies arts educators and partners located in schools and cultural organizations, where and how arts programming and partnerships are happening, and the existing needs for students and classrooms.
      </p>
      <p>
        Start exploring LEAD today! Together, we will strengthen Jacksonville through the arts.
      </p>
    `,
  },
  tagline: 'Understanding How the Arts Add Up in Duval County',
  partnerPortal: {
    dashboard: {
      welcomeMessage: `
        <h3><em>artlook<sup>®</sup> Jacksonville</em> is YOUR gallery for arts education data</h3>
        <p>
          <em>artlook<sup>®</sup> Jacksonville</em> is the only platform of its kind that combines data from Duval County Public Schools and arts education program providers (like you!). The goal is to build direct connections between arts providers and schools to expand equitable access to quality arts education programs for EVERY student in Duval County.
        </p>
        <p>
          <strong>To get started, follow the links in the boxes below to share your organization's program and school partnership information.</strong>
        </p>
        <p>
          Visit <a href="https://jacksonville.artlookmap.com/" target="_blank" rel="noopener noreferrer">jacksonville.artlookmap.com</a> to find schools to partner with, connect with other arts organizations, and learn more about what's going on in Duval County.
        </p>
        <p>
          If you have any questions or need assistance, please contact <a href="mailto:LEAD@capkids.org" target="_blank" rel="noopener noreferrer">LEAD@capkids.org</a>.
        </p>
      `,
    },
  },
  schoolPortal: {
    dashboard: {
      welcomeMessage: `
        <h3><em>artlook<sup>®</sup> Jacksonville</em> is YOUR gallery for arts education data</h3>
          <p>
            <em>artlook<sup>®</sup> Jacksonville</em> is the only platform of its kind that combines data from Duval County Public Schools (like you!) and arts education program providers. The goal is to build direct connections between arts providers and schools to expand equitable access to quality arts education programs for EVERY student in Duval County.
          </p>
          <p>
            <strong>To get started, follow the links in the boxes below to share your organization's program and school partnership information.</strong>
          </p>
          <p>
            Visit <a href="https://jacksonville.artlookmap.com/" target="_blank" rel="noopener noreferrer">jacksonville.artlookmap.com</a> to find arts organizations to partner with and learn more about what's going on in Duval County.
          </p>
          <p>
            If you have any questions or need assistance, please contact <a href="mailto:LEAD@capkids.org" target="_blank" rel="noopener noreferrer">LEAD@capkids.org</a>.
          </p>
      `,
    },
  },
  survey: {
    getStarted: `<h2>Welcome to the {{ surveySchoolYearNumber }} Creative Schools Survey!</h2>
    <p>
      Through this annual survey, you will be asked to share information about arts education staffing and instruction at your school, partnerships with arts partners (i.e. arts organizations), and how your school budgets and plans for the arts.
    </p>
    <p>
      As you work on the {{ surveySchoolYearNumber }} survey, please be sure to frequently save your responses to each question to avoid losing information. You are not required to complete your survey in one session; saving your responses as you go will allow you to return at any time to complete or edit your survey before submitting.
    </p><br/>
    <p>
      Please feel free to reach out to <a href="mailto:LEAD@capkids.org" target="_blank" rel="noopener noreferrer">LEAD@capkids.org</a> with any questions!
    </p>
    `,
    staffingQuestion: {
      modalMessage: `
        <p>
        <strong>Note on instructors:</strong> Restrict the list of instructors in your school to those teaching an arts course during the school day. Note that district-run/district-managed schools require arts instructors to hold a state certification or endorsement in one of the following arts disciplines: visual arts, media arts, music, theater, and/or dance. Charter schools, contract schools, and Pre-K-only schools do not have this requirement. If itinerant teachers teach at your school, please report those individuals as well.
        </p>
        <p>
        <strong>Note on part/full-time status:</strong> Please select Full Time if a teacher had full-time status at your school for the entire school year. If a teacher was part-time at your school for all or part of the year, or full-time for only part of the year, please select Part Time.
        </p>
        <p>
        <strong>Note on disciplines:</strong> The Primary Focus of each instructor is required. You may optionally list a Secondary Focus as well. Please also select the “Classroom/non-arts teacher?” button if the instructor is not primarily an arts instructor (e.g., non-arts, general education teacher teaching arts courses).
        </p>
        `,
      displayOptions: {
        showClassNumber: true,
      },
    },
    instructorQuestion: {
      displayOptions: {
        showInstructorEmail: true,
      },
    },
  },
}

// ----- SURVEY QUESTIONS CONFIG -----

const {
  APPROACHES,
  GOVERNANCE,
  OBSTACLES_SELECT,
  OBSTACLES_DESCRIBE,
  OBSTACLES_OVERCOME,
  DISTRICT_SPENDING,
  COMMUNITY_ENGAGEMENTS,
} = SURVEY_QUESTIONS

config.surveyQuestions = {
  approaches: APPROACHES.OPTIONS.PER_DISCIPLINE,
  governance: GOVERNANCE.OPTIONS.GENERAL,
  obstaclesSelect: OBSTACLES_SELECT.OPTIONS.GENERAL,
  obstaclesDescribe: OBSTACLES_DESCRIBE.OPTIONS.GENERAL,
  obstaclesOvercome: OBSTACLES_OVERCOME.OPTIONS.GENERAL,
  districtSpending: DISTRICT_SPENDING.OPTIONS.GENERAL,
  communityEngagement: COMMUNITY_ENGAGEMENTS.OPTIONS.GENERAL,
}

export default config
